import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <h2 {...{
      "id": "installing-your-niceview"
    }}>{`Installing your nice!view`}</h2>
    <h3 {...{
      "id": "using-an-existing-oled-compatible-shield"
    }}>{`Using an existing OLED compatible shield`}</h3>
    <ul>
      <li parentName="ul">{`Cut off one of the positions of both the socket and header`}</li>
      <li parentName="ul">{`Solder the left over 4 pin socket to your shield`}</li>
      <li parentName="ul">{`Solder the left over 4 pin header to your nice!view's left 4 pins (not the CS pin!)`}</li>
      <li parentName="ul">{`Create a bodge wire from the CS pin to the Arduino Digital 1 (D1) Pro Micro pin (P0.06/006 on the nice!nano)`}
        <ul parentName="li">
          <li parentName="ul">{`If the D1 pin is unavailable, you'll need to override the `}<inlineCode parentName="li">{`cs-gpios`}</inlineCode>{` on the adapter or define your own `}<inlineCode parentName="li">{`&nice_view_spi`}</inlineCode>{` bus without the `}<inlineCode parentName="li">{`nice_view_adapter`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Insert the header into the socket`}</li>
    </ul>
    <h3 {...{
      "id": "using-a-niceview-native-compatible-shield"
    }}>{`Using a nice!view native compatible shield`}</h3>
    <ul>
      <li parentName="ul">{`Solder the 5 pin socket to your shield`}</li>
      <li parentName="ul">{`Solder the 5 pin header to the nice!view`}</li>
      <li parentName="ul">{`Insert the header into the socket`}</li>
    </ul>
    <h3 {...{
      "id": "protection-film"
    }}>{`Protection Film`}</h3>
    <p>{`The display of the nice!view comes with a protection film installed to protect the display before usage.
You may remove the film after installation or leave it on if you prefer.`}</p>
    <h2 {...{
      "id": "using-with-zmk"
    }}>{`Using with ZMK`}</h2>
    <p>{`After successfully installing your nice!view, all that should be required is to build your board with the `}<inlineCode parentName="p">{`nice_view`}</inlineCode>{` shield and possibly the `}<inlineCode parentName="p">{`nice_view_adapter`}</inlineCode>{` if you're using a non-native shield.`}</p>
    <h3 {...{
      "id": "zmk-config-repo"
    }}>{`ZMK Config Repo`}</h3>
    <p>{`With a ZMK config repo, edit the `}<inlineCode parentName="p">{`build.yaml`}</inlineCode>{` file to have `}<inlineCode parentName="p">{`nice_view`}</inlineCode>{` added to the end of each shield string.
If you're using an OLED compatible shield, you'll also need to add `}<inlineCode parentName="p">{`nice_view_adapter`}</inlineCode>{` to the list of shields `}<em parentName="p">{`first`}</em>{`.`}</p>
    <p>{`Native example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`include:
  - board: nice_nano_v2
-   shield: some_native_shield
+   shield: some_native_shield nice_view
`}</code></pre>
    <p>{`Non-native example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`include:
  - board: nice_nano_v2
-   shield: lily58_left
+   shield: lily58_left nice_view_adapter nice_view
  - board: nice_nano_v2
-   shield: lily58_right
+   shield: lily58_right nice_view_adapter nice_view
`}</code></pre>
    <h3 {...{
      "id": "manual-zmk-build"
    }}>{`Manual ZMK build`}</h3>
    <p>{`When building manually, all that needs to be done is adding `}<inlineCode parentName="p">{`nice_view`}</inlineCode>{` to the shield build string.
If you're using an non-native but OLED compatible shield, you'll also need to add `}<inlineCode parentName="p">{`nice_view_adapter`}</inlineCode>{` to the list of shields `}<em parentName="p">{`first`}</em>{`.`}</p>
    <p>{`Native example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- west build -p -b nice_nano_v2 -- -DSHIELD="some_native_shield"
+ west build -p -b nice_nano_v2 -- -DSHIELD="some_native_shield nice_view"
`}</code></pre>
    <p>{`Non-native shield:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- west build -p -b nice_nano_v2 -- -DSHIELD="lily58_left"
+ west build -p -b nice_nano_v2 -- -DSHIELD="lily58_left nice_view_adapter nice_view"
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      